import { Paper } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../store/rootReducer';
import TallenesTaleTableToolbar from '../components/TallenesTaleTableToolbar';
import TallenesTaleListGruppe from './TallenesTaleListGruppe';
import TallenesTaleListHoved from './TallenesTaleListHoved';
import TallenesTaleListKunde from './TallenesTaleListKunde';
import TallenesTaleListSum from './TallenesTaleListSum';
import TallenesTaleListVaregruppe from './TallenesTaleListVaregruppe';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { marginLeft: '20px', marginRight: '20px', fontSize: '1em !important', color: '#456456', },
  }),
);

//
export default function TallenesTaleReport(props) {
  const classes = useStyles();
  const visningType: string = useSelector((state) => (state as AppState).tallenesTale.visningType);
  //
  const handleVisningChangedComponent = (val) => {
    switch (true) {
      case val === 'Kunde': return <TallenesTaleListKunde />
      case val === 'Varegruppe': return <TallenesTaleListVaregruppe />
      case val === 'Gruppe': return <TallenesTaleListGruppe />
      case val === 'Hovedgruppe': return <TallenesTaleListHoved />
      case val === 'Summeringsgruppe': return <TallenesTaleListSum />
      default: return null;
    }
  }
  //
  return (
    <div className={classes.root} >
      <Paper>
        <TallenesTaleTableToolbar />
        {handleVisningChangedComponent(visningType)}
      </Paper>
    </div>
  )
}