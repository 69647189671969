import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import PageAddUser from '../app/admin-user/PageAddUser';
import PageUserList from '../app/admin-user/PageUserList';
import Home from '../app/home/Home';
import LandingPage from '../app/landing-page/LandingPage';
import Logout from '../app/logout/Logout';
import PasswordLinkRecovery from '../app/password/PasswordLinkRecovery';
import PasswordUpdate from '../app/password/PasswordUpdate';
import PDFForm from '../temp/pdf/PDFForm';
import LPSupport from '../app/support/_LPSupport';
import VSEPage from '../pages/_heirloom/VSEPage';
import U from './Utils';
import RequireAuth from './requireAuth';
import PageProfil from '../app/admin-user/PageProfil';
import ACC1Page from '../pages/ACC1/ACC1Page';
import TallenesTalePage from '../pages/SS10/TallenesTalePage';
import CriteriaItemPage from '../pages/SS11/CriteriaItemPage';




const getRoutes = () => {
  const renderRootMenuItems = () => {
    const JSXItemEl: any = [];
    let i = 0;
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/account`} component={RequireAuth(ACC1Page, 'ACC1')} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/home`} component={Home} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/logout`} component={Logout} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/login.html`} component={Home} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/login`} component={Home} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/logout`} component={Home} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/menu`} component={RequireAuth(LandingPage)} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/vse`} component={RequireAuth(VSEPage)} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/support`} component={RequireAuth(LPSupport)} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/passwordlinkrecovery`} component={PasswordLinkRecovery} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/passwordupdate`} component={PasswordUpdate} />);
    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/pdf`} component={PDFForm} />);
    // Gives access to 31 users' roles
    if ((U?.T_roles as string[])?.includes('ROLE_31') || (U?.T_roles as string[])?.includes('ROLE_32')) {
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/SS10`} component={RequireAuth(TallenesTalePage)} />);
    }
    if ((U?.T_roles as string[])?.includes('ROLE_32')) {
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/SS11`} component={RequireAuth(CriteriaItemPage)} />);
    }
    if ((U?.T_roles as string[])?.includes('ROLE_ADMIN')) {
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/BR00`} component={RequireAuth(PageUserList)} />);
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/BR01`} component={RequireAuth(PageAddUser)} />);
    }
    // Else is handled by the server API - When ADMIN, MINIADMIN is removed
    if ((U?.T_roles as string[])?.includes('ROLE_MINIADMIN')) {
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/BR02`} component={RequireAuth(PageUserList)} />);
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/BR03`} component={RequireAuth(PageAddUser)} />);
    }
    // Gives access to 22 users' roles
    if ((U?.T_roles as string[])?.includes('ROLE_22')) {
      JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/ACC1`} component={RequireAuth(ACC1Page, 'ACC1')} />);
    }

    JSXItemEl.push(<Route exact key={i++} path={`${U.HOMEPAGE_CONTEXT}/BR04`} component={RequireAuth(PageProfil)} />);
    // Redirect to menu when no match found
    JSXItemEl.push(<Redirect exact key={i++} from={'*'} to={`${U.HOMEPAGE_CONTEXT}/menu`} />);
    //
    return JSXItemEl;
  };
  return <Switch>{renderRootMenuItems()}</Switch>;
}

export default getRoutes;
