import LuxonUtils from '@date-io/luxon';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import DeleteIcon from "@material-ui/icons/Delete";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import classNames from "classnames";
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { AppState } from '../../../store/rootReducer';

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing(1)
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto"
    }
});

let ACC1FormToolbar = props => {
    const dispatch = useDispatch();
    const { classes } = props;
    const date = useSelector(state => (state as AppState).accounting.date);
    const open = useSelector(state => (state as AppState).accounting.isAccountingDialogOpen);
    const selected = useSelector(state => (state as AppState).accounting.selectedJobList);
    const handleDateChange = (val: any) => {
        var _tmpDate = new Date();
        _tmpDate.setTime(val);
        dispatch(actions.accountingJobListSetDate(_tmpDate));
    }
    const getUpdatedListFromDate = () => dispatch(actions.accountingJobListGet());
    const markSelectedDeleted = () => dispatch(actions.accountingDeletionDialogUpdated(true));
    const handleDiagClickDelete = () => dispatch(actions.accountingJobListSelectedDeletionPost());
    const handleDiagClose = () => dispatch(actions.accountingDeletionDialogUpdated(false));

    return (
        <Toolbar
            className={classNames(classes.root, { [classes.highlight]: selected.length > 0 })}>
            <Dialog
                open={open}
                onClose={handleDiagClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Slett valgte ?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Valgte poster fra databasen vil bli merket som skjult.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDiagClose} color="primary">
                        Avbryt
                    </Button>
                    <Button onClick={handleDiagClickDelete} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <div className={classes.title}>
                {selected.length > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {selected.length} selected
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">
                        Accounting
                    </Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {selected.length > 0 ? (
                    <Tooltip title="Delete">
                        <IconButton aria-label="Delete" onClick={() => markSelectedDeleted()}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                ) : (
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <DatePicker
                            variant="inline"
                            openTo="year"
                            views={["year", "month"]}
                            autoOk={true}
                            value={date}
                            onChange={newDate => { handleDateChange(newDate) }}
                            onClose={() => getUpdatedListFromDate()}
                        />
                    </MuiPickersUtilsProvider>
                )}
            </div>
        </Toolbar>
    );
};

export default withStyles(toolbarStyles)(ACC1FormToolbar);
