import { TableCell, TableHead, TableRow, TableSortLabel, Tooltip } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from 'react-redux';
import TaleSearchParams from '../../../models/TaleSearchParams';
import { AppState } from "../../../store/rootReducer";

class TallenesTaleTableHeadSum extends Component<Props> {

  rows = [
    { id: "navn", numeric: false, disablePadding: false, label: "Summeringsgruppe" },
    { id: "nettA", numeric: false, disablePadding: false, label: "i år" },
    { id: "nettB", numeric: false, disablePadding: false, label: "i fjor" },
    { id: "diff", numeric: false, disablePadding: false, label: "diff." },
    { id: "percent", numeric: false, disablePadding: false, label: "%" },
  ];

  componentDidUpdate() { if (this.props.taleSearch !== undefined) { this.updateRowLabels(); } }

  updateRowLabels = () => {
    if (this.props.taleSearch)
      this.rows = [
        { id: "navn", numeric: false, disablePadding: false, label: "Summeringsgruppe" },
        {
          id: "nettA", numeric: false, disablePadding: false, label: (this.props.taleSearch?.brutto ? 'Brutto' : 'Netto') + ' i år '
            + this.props.taleSearch?.displayDate.substring(0, 17)
        },
        {
          id: "nettF", numeric: false, disablePadding: false, label: (this.props.taleSearch?.brutto ? 'Brutto' : 'Netto') + ' i fjor '
            + this.props.taleSearch?.displayDate.substring(18, this.props.taleSearch?.displayDate.length)
        },
        { id: "diff", numeric: false, disablePadding: false, label: (this.props.taleSearch?.brutto ? 'Brutto' : 'Netto') + ' diff.' },
        { id: "percent", numeric: false, disablePadding: false, label: "%" },
      ];
    return this.rows;
  };

  createSortHandler = property => event => { this.props.onRequestSort(event, property); };

  render() {
    //
    return (
      <TableHead>
        <TableRow>
          {this.updateRowLabels().map(
            row => {
              return (
                <TableCell
                  key={row.id}
                  align={row.id === 'navn' ? "left" : "right"}
                  sortDirection={(this.props.orderBy === row.id ? (this.props.order === "desc" ? "desc" : "asc") : "desc")/**/}
                >
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={this.props.orderBy === row.id}
                      direction={(this.props.order === "desc" ? "desc" : "asc")}
                      onClick={this.createSortHandler(row.id)}
                    >{row.label}</TableSortLabel>
                  </Tooltip>
                </TableCell>
              )
            })}
        </TableRow>
      </TableHead>
    );
  }
}

interface OwnProps {
  order?: string;
  orderBy?: string;
  onRequestSort: (event: any, property: any) => void;
  rowCount: number;
  classes?: any, //place Holder
}

interface StateProps {
  selectedCriteriaItemList: number[];
  taleSearch: TaleSearchParams;
  classes?: Partial<Record<"tableCell" | "spacer", string>>;
}

type Props = OwnProps & StateProps/* & DispatchProps*/;

export function mapStateToProps(state: AppState): StateProps {
  return {
    selectedCriteriaItemList: state.tallenesTale.selectedCriteriaItemList,
    taleSearch: state.tallenesTale.taleSearchSGruppe,
  };
}

export default connect<StateProps, undefined, OwnProps, AppState>(mapStateToProps, null)(TallenesTaleTableHeadSum);